export const BASE_API_URL = 'https://api.podcastindex.org/api/1.0/';
export const PATH_SEARCH_BY_TERM = 'search/byterm';
export const PATH_SEARCH_EPISODE_BY_PERSON = 'search/byperson';
export const PATH_ADD_BY_FEED_URL = 'add/byfeedurl';
export const PATH_ADD_BY_ITUNES_ID = 'add/byitunesid';
export const PATH_EPISODES_BY_FEED_ID = 'episodes/byfeedid';
export const PATH_EPISODES_BY_FEED_URL = 'episodes/byfeedurl';
export const PATH_EPISODES_BY_ITUNES_ID = 'episodes/byitunesid';
export const PATH_EPISODES_BY_ID = 'episodes/byid';
export const PATH_EPISODES_RANDOM = 'episodes/random';
export const PATH_PODCASTS_BY_FEED_URL = 'podcasts/byfeedurl';
export const PATH_PODCASTS_BY_FEED_ID = 'podcasts/byfeedid';
export const PATH_PODCASTS_BY_ITUNES_ID = 'podcasts/byitunesid';
export const PATH_PODCASTS_BY_TAG = 'podcasts/bytag';
export const PATH_PODCASTS_TRENDING = 'podcasts/trending';
export const PATH_PODCASTS_DEAD = 'podcasts/dead';
export const PATH_RECENT_FEEDS = 'recent/feeds';
export const PATH_RECENT_EPISODES = 'recent/episodes';
export const PATH_RECENT_NEWFEEDS = 'recent/newfeeds';
export const PATH_RECENT_SOUNDBITES = 'recent/soundbites';
export const PATH_VALUE_BY_FEED_ID = 'value/byfeedid';
export const PATH_VALUE_BY_FEED_URL = 'value/byfeedurl';
export const PATH_STATS_CURRENT = 'stats/current';
export const PATH_CATEGORIES_LIST = 'categories/list';
export const PATH_HUB_PUBNOTIFIY = 'hub/pubnotify';
